import _defineProperty from "/home/daniel/Proyectos/gcom/frontend/node_modules/@babel/runtime-corejs2/helpers/defineProperty";

var _mutations;

import auth from "../api/auth";
import { ACTIVATION_BEGIN, ACTIVATION_CLEAR, ACTIVATION_FAILURE, ACTIVATION_SUCCESS, REGISTRATION_BEGIN, REGISTRATION_CLEAR, REGISTRATION_FAILURE, REGISTRATION_FAILURE_MSG_FORM, REGISTRATION_SUCCESS } from "./types";
export default {
  namespaced: true,
  state: {
    activationCompleted: false,
    activationError: false,
    activationLoading: false,
    registrationCompleted: false,
    registrationError: false,
    registrationLoading: false,
    resgitrationErrorMsg: {}
  },
  actions: {
    createAccount: function createAccount(_ref, _ref2) {
      var commit = _ref.commit;
      var password1 = _ref2.password1,
          password2 = _ref2.password2,
          email = _ref2.email,
          comunidad = _ref2.comunidad;
      commit(REGISTRATION_BEGIN);
      return auth.createAccount(email, password1, password2, email, comunidad).then(function () {
        return commit(REGISTRATION_SUCCESS);
      }).catch(function (response) {
        var resp = response.response;

        if (typeof resp === 'undefined') {
          commit(REGISTRATION_FAILURE);
          return;
        }

        if (resp.headers['content-type'] === 'application/json') {
          commit(REGISTRATION_FAILURE_MSG_FORM, resp);
        }
      });
    },
    activateAccount: function activateAccount(_ref3, _ref4) {
      var commit = _ref3.commit;
      var key = _ref4.key;
      commit(ACTIVATION_BEGIN);
      return auth.verifyAccountEmail(key).then(function () {
        return commit(ACTIVATION_SUCCESS);
      }).catch(function () {
        return commit(ACTIVATION_FAILURE);
      });
    },
    clearRegistrationStatus: function clearRegistrationStatus(_ref5) {
      var commit = _ref5.commit;
      commit(REGISTRATION_CLEAR);
    },
    clearActivationStatus: function clearActivationStatus(_ref6) {
      var commit = _ref6.commit;
      commit(ACTIVATION_CLEAR);
    }
  },
  mutations: (_mutations = {}, _defineProperty(_mutations, ACTIVATION_BEGIN, function (state) {
    state.activationLoading = true;
  }), _defineProperty(_mutations, ACTIVATION_CLEAR, function (state) {
    state.activationCompleted = false;
    state.activationError = false;
    state.activationLoading = false;
  }), _defineProperty(_mutations, ACTIVATION_FAILURE, function (state) {
    state.activationError = true;
    state.activationLoading = false;
  }), _defineProperty(_mutations, ACTIVATION_SUCCESS, function (state) {
    state.activationCompleted = true;
    state.activationError = false;
    state.activationLoading = false;
  }), _defineProperty(_mutations, REGISTRATION_BEGIN, function (state) {
    state.registrationLoading = true;
  }), _defineProperty(_mutations, REGISTRATION_CLEAR, function (state) {
    state.registrationCompleted = false;
    state.registrationError = false;
    state.registrationLoading = false;
  }), _defineProperty(_mutations, REGISTRATION_FAILURE, function (state) {
    state.registrationError = true;
    state.registrationLoading = false;
  }), _defineProperty(_mutations, REGISTRATION_FAILURE_MSG_FORM, function (state, resp) {
    // state.registrationError = true;
    state.registrationLoading = false;

    if (resp.headers['content-type'] === 'application/json') {
      state.resgitrationErrorMsg = resp.data;
    }
  }), _defineProperty(_mutations, REGISTRATION_SUCCESS, function (state) {
    state.registrationCompleted = true;
    state.registrationError = false;
    state.registrationLoading = false;
  }), _mutations)
};