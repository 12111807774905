export var LOGIN_BEGIN = 'LOGIN_BEGIN';
export var LOGIN_CLEAR = 'LOGIN_CLEAR';
export var LOGIN_FAILURE = 'LOGIN_FAILURE';
export var LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export var LOGIN_SUCCESS_SOCIAL = 'LOGIN_SUCCESS_SOCIAL';
export var LOGOUT = 'LOGOUT';
export var SET_TOKEN = 'SET_TOKEN';
export var SET_USER_ID = 'SET_USER_ID';
export var SET_USER_NAME = 'SET_USER_NAME';
export var SET_RULES_USER = 'SET_RULES_USER';
export var SET_MEMBER_STATE = 'SET_MEMBER_STATE';
export var ACCOUNT_DETAIL = 'ACCOUNT_DETAIL';
export var REMOVE_USER_NAME = 'REMOVE_USER_NAME';
export var REMOVE_USER_STATE = 'REMOVE_USER_STATE';
export var REMOVE_TOKEN = 'REMOVE_TOKEN';
export var REMOVE_FULL_NAME = 'REMOVE_FULL_NAME';
export var REMOVE_RULES = 'REMOVE_RULES';
export var PASSWORD_EMAIL_BEGIN = 'PASSWORD_EMAIL_BEGIN';
export var PASSWORD_EMAIL_CLEAR = 'PASSWORD_EMAIL_CLEAR';
export var PASSWORD_EMAIL_FAILURE = 'PASSWORD_EMAIL_FAILURE';
export var PASSWORD_EMAIL_SUCCESS = 'PASSWORD_EMAIL_SUCCESS';
export var PASSWORD_RESET_BEGIN = 'PASSWORD_RESET_BEGIN';
export var PASSWORD_RESET_CLEAR = 'PASSWORD_RESET_CLEAR';
export var PASSWORD_RESET_FAILURE = 'PASSWORD_RESET_FAILURE';
export var PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export var PASSWORD_CHANGE_BEGIN = 'PASSWORD_CHANGE_BEGIN';
export var PASSWORD_CHANGE_CLEAR = 'PASSWORD_CHANGE_CLEAR';
export var PASSWORD_CHANGE_FAILURE = 'PASSWORD_CHANGE_FAILURE';
export var PASSWORD_CHANGE_SUCCESS = 'PASSWORD_CHANGE_SUCCESS';
export var ACTIVATION_BEGIN = 'ACTIVATION_BEGIN';
export var ACTIVATION_CLEAR = 'ACTIVATION_CLEAR';
export var ACTIVATION_FAILURE = 'ACTIVATION_FAILURE';
export var ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS';
export var REGISTRATION_BEGIN = 'REGISTRATION_BEGIN';
export var REGISTRATION_CLEAR = 'REGISTRATION_CLEAR';
export var REGISTRATION_FAILURE = 'REGISTRATION_FAILURE';
export var REGISTRATION_FAILURE_MSG_FORM = 'REGISTRATION_FAILURE_MSG_FORM';
export var REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';