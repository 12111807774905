import Vue from 'vue';
import Vuex from 'vuex';
import auth from "./auth";
import signup from "./signup";
import main from "./main";
var debug = process.env.NODE_ENV !== 'production';
Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    auth: auth,
    signup: signup,
    main: main
  }
});