import session from "./session";
export default {
  login: function login(username, password) {
    return session.post('api/v1/auth/login/', {
      username: username,
      password: password
    });
  },
  loginFB: function loginFB(access_token) {
    return session.post('api/v1/auth/facebook/', {
      access_token: access_token
    });
  },
  getAccountDetails: function getAccountDetails() {
    return session.get('api/v1/auth/user/');
  },
  getAclUser: function getAclUser() {
    return session.get('api/v1/main/plv/');
  },
  updateAccount: function updateAccount(data) {
    return session.patch('api/v1/auth/user/', data);
  },
  sendAccountPasswordResetEmail: function sendAccountPasswordResetEmail(email) {
    return session.post('api/v1/auth/password/reset/', {
      email: email
    });
  },
  resetAccountPassword: function resetAccountPassword(uid, token, new_password1, new_password2) {
    return session.post('api/v1/auth/password/reset/confirm/', {
      uid: uid,
      token: token,
      new_password1: new_password1,
      new_password2: new_password2
    });
  },
  changeAccountPassword: function changeAccountPassword(oldPassword, newPassword1, newPassword2) {
    return session.post('api/v1/auth/password/change/', {
      old_password: oldPassword,
      new_password1: newPassword1,
      new_password2: newPassword2
    });
  },
  createAccount: function createAccount(username, password1, password2, email, comunidad) {
    return session.post('/api/v1/register/', {
      username: username,
      password1: password1,
      password2: password2,
      email: email,
      comunidad: comunidad
    });
  },
  verifyAccountEmail: function verifyAccountEmail(key) {
    return session.post('/api/v1/register/verify-email/', {
      key: key
    });
  }
};