/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.conf.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/
import lang from 'quasar/lang/es';
import Vue from 'vue';
import Quasar from 'quasar/src/vue-plugin.js';import Ripple from 'quasar/src/directives/Ripple.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';import GoBack from 'quasar/src/directives/GoBack.js';import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';import Dialog from 'quasar/src/plugins/Dialog.js';;
Vue.use(Quasar, {
  config: {
    "loading": {
      "spinnerColor": "cyan-1",
      "backgroundColor": "cyan-10"
    },
    "notify": {
      "actions": [{
        "icon": "close",
        "color": "white"
      }],
      "icon": "check"
    }
  },
  lang: lang,
  directives: {
    Ripple: Ripple,
    ClosePopup: ClosePopup,
    GoBack: GoBack
  },
  plugins: {
    Notify: Notify,
    Loading: Loading,
    Dialog: Dialog
  }
});