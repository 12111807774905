export default {
  nombreComunidad: null,
  nombreCategoria: null,
  // Guarda info de la pagina de detalles de la comunidad
  stDetalleComunidad: {
    tabActivo: 'directorios',
    // Panel expandible activo en el tab "Encuestas"
    panelEncuestasActivo: {
      abiertas: true,
      cerradas: false,
      noPublicas: false
    }
  },
  leftDrawerOpen: false,
  // Establecimientos
  dialogEditProperty: false,
  propiedades: [],
  propiedad: {},
  // Id del usuario que edita el administrador para
  // para obtenelo a la hora de crear una propiedad
  idUserProperty: null
};