import "core-js/modules/web.dom.iterable";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.promise";
import "core-js/modules/es6.object.to-string";
import _defineProperty from "/home/daniel/Proyectos/gcom/frontend/node_modules/@babel/runtime-corejs2/helpers/defineProperty";

var _mutations;

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import auth from "../api/auth";
import session from "../api/session";
import _ from 'lodash';
import { LOGIN_BEGIN, LOGIN_FAILURE, LOGIN_SUCCESS, LOGIN_SUCCESS_SOCIAL, LOGOUT, SET_TOKEN, SET_USER_NAME, SET_USER_ID, SET_MEMBER_STATE, SET_RULES_USER, REMOVE_TOKEN, REMOVE_USER_NAME, REMOVE_USER_STATE, REMOVE_FULL_NAME, REMOVE_RULES, ACCOUNT_DETAIL, PASSWORD_RESET_BEGIN, PASSWORD_RESET_CLEAR, PASSWORD_RESET_FAILURE, PASSWORD_RESET_SUCCESS, PASSWORD_EMAIL_BEGIN, PASSWORD_EMAIL_CLEAR, PASSWORD_EMAIL_FAILURE, PASSWORD_EMAIL_SUCCESS, PASSWORD_CHANGE_BEGIN, PASSWORD_CHANGE_FAILURE, PASSWORD_CHANGE_SUCCESS } from "./types";
import main from "../api/main";
var TOKEN_STORAGE_KEY = 'TOKEN_STORAGE_KEY';
var USER_ID = 'USER_ID';
var USER_VERIFIED = 'USER_VERIFIED';
var USER_NAME = 'USER_NAME';
var FULL_NAME = 'FULL_NAME';
var RULES_USER = 'RULES_USER';
var state = {
  authenticating: false,
  error: false,
  errorText: 'Vaya, el usuario o la contraseña no son correctos.',
  token: null,
  userId: null,
  username: null,
  full_name: null,
  userVerified: false,
  rules: [],
  emailCompleted: false,
  emailError: false,
  emailLoading: false,
  emailErrorMsg: [{
    email: ''
  }],
  resetCompleted: false,
  resetError: false,
  resetLoading: false,
  resetErrorMsg: [{
    new_password1: '',
    new_password2: '',
    token: ''
  }],
  passwordChangeLoading: false,
  passwordChange: true,
  passwordChangeError: false,
  passwordChangeCompleted: false,
  passwordChangeErrorMsg: [{
    old_password: '',
    new_password1: '',
    new_password2: ''
  }],
  socialAuth: false
};
var getters = {
  isAuthenticated: function isAuthenticated(state) {
    return !!state.token;
  },
  isSocialAuth: function isSocialAuth(state) {
    return state.socialAuth;
  },
  isVerified: function isVerified(state) {
    return state.userVerified;
  }
};
var mutations = (_mutations = {}, _defineProperty(_mutations, LOGIN_BEGIN, function (state) {
  state.authenticating = true;
  state.error = false;
}), _defineProperty(_mutations, LOGIN_FAILURE, function (state, error) {
  var response = error.response;

  if (response && response.status) {
    // Errores 400
    if (parseInt(response.status / 100) === 4) {
      if (error.response.data) {
        state.errorText = '';

        for (var key in error.response.data) {
          state.errorText += error.response.data[key];
        }
      }
    } else {
      state.errorText = "Error, el servidor a devuelto el error: ".concat(response.status, " - ").concat(response.statusText);
    }
  } else {
    state.errorText = 'Error en la autenticación, espere uno minutos y vuelve a intentarlo.';
  }

  state.authenticating = false;
  state.error = true;
}), _defineProperty(_mutations, LOGIN_SUCCESS, function (state) {
  state.authenticating = false;
  state.error = false;
}), _defineProperty(_mutations, LOGIN_SUCCESS_SOCIAL, function (state) {
  state.socialAuth = true;
}), _defineProperty(_mutations, LOGOUT, function (state) {
  state.authenticating = false;
  state.error = false;
  state.socialAuth = false;
}), _defineProperty(_mutations, SET_TOKEN, function (state, token) {
  sessionStorage.setItem(TOKEN_STORAGE_KEY, token);
  session.defaults.headers.Authorization = "Token ".concat(token);
  state.token = token;
}), _defineProperty(_mutations, SET_USER_ID, function (state, token) {
  sessionStorage.setItem(USER_ID, token);
  state.userId = token;
}), _defineProperty(_mutations, SET_USER_NAME, function (state, user) {
  sessionStorage.setItem(USER_NAME, user);
  state.username = user;
}), _defineProperty(_mutations, SET_MEMBER_STATE, function (state, verificado) {
  state.userVerified = verificado;
  sessionStorage.setItem(USER_VERIFIED, verificado);
}), _defineProperty(_mutations, ACCOUNT_DETAIL, function (state, full_name) {
  sessionStorage.setItem(FULL_NAME, full_name);
  state.full_name = full_name;
}), _defineProperty(_mutations, SET_RULES_USER, function (state, rules) {
  sessionStorage.setItem(RULES_USER, JSON.stringify(rules));
}), _defineProperty(_mutations, REMOVE_TOKEN, function (state) {
  sessionStorage.removeItem(TOKEN_STORAGE_KEY);
  delete session.defaults.headers.Authorization;
  state.token = null;
}), _defineProperty(_mutations, REMOVE_USER_NAME, function (state) {
  sessionStorage.removeItem(USER_ID);
  sessionStorage.removeItem(USER_NAME);
  state.userId = null;
  state.username = null;
}), _defineProperty(_mutations, REMOVE_USER_STATE, function (state) {
  sessionStorage.removeItem(USER_VERIFIED);
  state.userVerified = false;
}), _defineProperty(_mutations, REMOVE_FULL_NAME, function (state) {
  sessionStorage.removeItem(FULL_NAME);
  state.full_name = null;
}), _defineProperty(_mutations, REMOVE_RULES, function (state) {
  sessionStorage.removeItem(RULES_USER);
  state.rules = null;
}), _defineProperty(_mutations, PASSWORD_RESET_BEGIN, function (state) {
  state.resetLoading = true;
}), _defineProperty(_mutations, PASSWORD_RESET_CLEAR, function (state) {
  state.resetCompleted = false;
  state.resetError = false;
  state.resetLoading = false;
}), _defineProperty(_mutations, PASSWORD_RESET_FAILURE, function (state, error) {
  state.resetError = true;
  state.resetLoading = false;
  state.resetErrorMsg = error;
}), _defineProperty(_mutations, PASSWORD_RESET_SUCCESS, function (state) {
  state.resetCompleted = true;
  state.resetError = false;
  state.resetLoading = false;
}), _defineProperty(_mutations, PASSWORD_EMAIL_BEGIN, function (state) {
  state.emailLoading = true;
}), _defineProperty(_mutations, PASSWORD_EMAIL_CLEAR, function (state) {
  state.emailCompleted = false;
  state.emailError = false;
  state.emailLoading = false;
}), _defineProperty(_mutations, PASSWORD_EMAIL_FAILURE, function (state, error) {
  state.emailError = true;
  state.emailLoading = false;
  state.emailErrorMsg = error;
}), _defineProperty(_mutations, PASSWORD_EMAIL_SUCCESS, function (state) {
  state.emailCompleted = true;
  state.emailError = false;
  state.emailLoading = false;
  state.emailErrorMsg = [{
    email: ''
  }];
}), _defineProperty(_mutations, PASSWORD_CHANGE_BEGIN, function (state) {
  state.passwordChangeLoading = true; // state.passwordChangeErrorMsg = []
}), _defineProperty(_mutations, PASSWORD_CHANGE_FAILURE, function (state, data) {
  state.passwordChangeLoading = false;
  state.passwordChangeError = true;
  state.passwordChangeErrorMsg = data;
}), _defineProperty(_mutations, PASSWORD_CHANGE_SUCCESS, function (state) {
  state.passwordChangeError = false;
  state.passwordChangeErrorMsg = [];
  state.passwordChangeLoading = false;
}), _mutations);
var actions = {
  // Inicio de sesion del usuario
  login: function login(_ref, _ref2) {
    var commit = _ref.commit,
        dispatch = _ref.dispatch;
    var username = _ref2.username,
        password = _ref2.password;
    commit(LOGIN_BEGIN);
    return auth.login(username, password).then(function (_ref3) {
      var data = _ref3.data;
      return commit(SET_TOKEN, data.key);
    }).then(function () {
      return dispatch('getAccountDetails');
    }).then(function () {
      return dispatch('getAclUser');
    }).then(function () {
      return dispatch('checkMember');
    }).then(function () {
      return commit(LOGIN_SUCCESS);
    }).catch(function (error) {
      commit(LOGIN_FAILURE, error); // Devolvemos un Promise que resulve el error para posible listener

      return Promise(function (resolve, reject) {
        reject(error);
      });
    });
  },
  loginFB: function loginFB(_ref4, _ref5) {
    var commit = _ref4.commit,
        dispatch = _ref4.dispatch;
    var access_token = _ref5.access_token;
    commit(LOGIN_BEGIN);
    return auth.loginFB(access_token).then(function (_ref6) {
      var data = _ref6.data;
      return commit(SET_TOKEN, data.key);
    }).then(function () {
      return dispatch('getAccountDetails');
    }).then(function () {
      return dispatch('getAclUser');
    }).then(function () {
      return dispatch('checkMember');
    }).then(function () {
      return commit(LOGIN_SUCCESS);
    }).then(function () {
      return commit(LOGIN_SUCCESS_SOCIAL);
    }).catch(function (error) {
      commit(LOGIN_FAILURE, null); // Devolvemos un Promise que resulve el error para posible listener

      return Promise(function (resolve, reject) {
        reject(error);
      });
    });
  },
  // Obtener informacion del perfil del usuario
  getAccountDetails: function getAccountDetails(_ref7) {
    var commit = _ref7.commit;
    return auth.getAccountDetails().then(function (data) {
      commit(SET_USER_ID, data.data.pk);
      commit(SET_USER_NAME, data.data.username); // Obtenemos el nombre completo del usuario

      var fullName = '';

      if (data.data.first_name && data.data.last_name) {
        fullName = data.data.first_name + ' ' + data.data.last_name;
      }

      commit(ACCOUNT_DETAIL, fullName);
    });
  },
  // Obtener permisos
  getAclUser: function getAclUser(_ref8) {
    var commit = _ref8.commit;
    var vm = this._vm;
    return auth.getAclUser().then(function (data) {
      var permissions = [];
      permissions.push({
        actions: data.data[0].name
      });

      var _iterator = _createForOfIteratorHelper(data.data[0].permissions),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var perm = _step.value;
          var action = perm.codename.split('_')[0];
          var subject = perm.codename.split('_')[1];
          permissions.push({
            subject: subject,
            actions: action
          });
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      vm.$ability.update(permissions);
      commit(SET_RULES_USER, permissions);
    });
  },
  // Comprueba si el usuario esta verificado
  checkMember: function checkMember(_ref9) {
    var commit = _ref9.commit;
    return main.getMiembro(sessionStorage.getItem(USER_NAME)).then(function (response) {
      // TODO: Mejorar esto
      var verificado = response.data[0].verificado;
      commit(SET_MEMBER_STATE, verificado);
    }, function () {
      var verificado = false;
      commit(SET_MEMBER_STATE, verificado);
    });
  },
  // Desconectar usuario. Eliminar token y username
  logout: function logout(_ref10) {
    var commit = _ref10.commit;
    commit(LOGOUT);
    commit(REMOVE_TOKEN);
    commit(REMOVE_USER_NAME);
    commit(REMOVE_USER_STATE);
    commit(REMOVE_FULL_NAME);
    commit(REMOVE_RULES);
  },
  // Funcion utilitaria para inicializar toke y nombre de usuario en cada url
  initialize: function initialize(_ref11) {
    var dispatch = _ref11.dispatch,
        commit = _ref11.commit;
    // Inicializamos token del usuario
    var token = sessionStorage.getItem(TOKEN_STORAGE_KEY);

    if (token) {
      commit(SET_TOKEN, token);
    } else {
      commit(REMOVE_TOKEN);
    }

    if (token) {
      // Obtenemos los permisos guardados en el sessionStorage
      var permissions = sessionStorage.getItem(RULES_USER);

      if (!permissions) {
        // Si no existen, los obtenemos del backend
        dispatch('getAclUser');
      } else {
        // Si existe, actualizamos los permisos del plugin "ability"
        var rules = JSON.parse(permissions);

        if (rules instanceof Array && rules.length) {
          this._vm.$ability.update(rules);
        }
      }
    } // Inicializamos el user ID desde el Local Storage


    var userId = sessionStorage.getItem(USER_ID);

    if (userId) {
      commit(SET_USER_ID, userId);
    }

    var userV = sessionStorage.getItem(USER_VERIFIED);

    if (userV !== undefined && userV !== null) {
      commit(SET_MEMBER_STATE, JSON.parse(userV));
    } // Incializammos nombre de usuario


    var username = sessionStorage.getItem(USER_NAME);

    if (username) {
      commit(SET_USER_NAME, username);
    } else {
      // Si no existe nombre de usario eleminamos los permisos
      commit(REMOVE_USER_NAME);
      commit(REMOVE_RULES);
    } // Inicializamos el nombre completo


    var fullName = sessionStorage.getItem(FULL_NAME);

    if (fullName) {
      commit(ACCOUNT_DETAIL, fullName);
    } else {
      commit(REMOVE_FULL_NAME);
    }
  },
  // Resetear el password de l usuario si lo pierde
  resetPassword: function resetPassword(_ref12, _ref13) {
    var commit = _ref12.commit;
    var uid = _ref13.uid,
        token = _ref13.token,
        password1 = _ref13.password1,
        password2 = _ref13.password2;
    commit(PASSWORD_RESET_BEGIN);
    return auth.resetAccountPassword(uid, token, password1, password2).then(function () {
      return commit(PASSWORD_RESET_SUCCESS);
    }).catch(function (_ref14) {
      var response = _ref14.response;
      return commit(PASSWORD_RESET_FAILURE, response.data);
    });
  },
  // Enviar email para resetear password de usuario.
  sendPasswordResetEmail: function sendPasswordResetEmail(_ref15, _ref16) {
    var commit = _ref15.commit;
    var email = _ref16.email;
    commit(PASSWORD_EMAIL_BEGIN);
    return auth.sendAccountPasswordResetEmail(email).then(function () {
      return commit(PASSWORD_EMAIL_SUCCESS);
    }).catch(function (_ref17) {
      var response = _ref17.response;
      return commit(PASSWORD_EMAIL_FAILURE, response.data);
    });
  },
  // Cambiar password del usuario.
  passwordChange: function passwordChange(_ref18, _ref19) {
    var commit = _ref18.commit,
        dispatch = _ref18.dispatch;
    var oldPassword = _ref19.oldPassword,
        newPassword1 = _ref19.newPassword1,
        newPassword2 = _ref19.newPassword2;
    commit(PASSWORD_CHANGE_BEGIN);
    return auth.changeAccountPassword(oldPassword, newPassword1, newPassword2).then(function (response) {
      commit(PASSWORD_CHANGE_SUCCESS);
    }).catch(function (error) {
      var data = _.property('response.data')(error) || {};
      commit(PASSWORD_CHANGE_FAILURE, data);
    });
  }
};
export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
};