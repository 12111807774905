import session from "./session";
export default {
  env: function env() {
    return process.env;
  },
  // Categorias
  listCategorias: function listCategorias() {
    var comunidad = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

    if (typeof comunidad === 'number') {
      return session.get("/api/v1/main/categoria/?comunidad=".concat(comunidad));
    } else {
      return session.get("/api/v1/main/categoria/");
    }
  },
  detalleCategoria: function detalleCategoria(idCategoria) {
    return session.get('/api/v1/main/categoria/' + idCategoria + '/');
  },
  nuevaCategoria: function nuevaCategoria(data) {
    return session.post('/api/v1/main/categoria/', data);
  },
  actualizarCategoria: function actualizarCategoria(data) {
    return session.patch('/api/v1/main/categoria/' + data.id + '/', data);
  },
  eliminarCategoria: function eliminarCategoria(idCategoria) {
    return session.delete('/api/v1/main/categoria/' + idCategoria + '/');
  },
  // Documentos publicos
  listCategoriaPublica: function listCategoriaPublica() {
    return session.get('/api/v1/main/documento-publico/');
  },
  deleteDocumentoPublico: function deleteDocumentoPublico(idDocumento) {
    return session.delete("/api/v1/main/documento-publico/".concat(idDocumento, "/"));
  },
  nuevoDocumentoPublico: function nuevoDocumentoPublico(data) {
    return session.post('/api/v1/main/documento-publico/', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  // Documentos
  listDocumentos: function listDocumentos() {
    return session.get('/api/v1/main/documento/');
  },
  nuevoDocumento: function nuevoDocumento(data) {
    return session.post('/api/v1/main/documento/', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  eliminarDocumento: function eliminarDocumento(idDocumento) {
    return session.delete('/api/v1/main/documento/' + idDocumento + '/');
  },
  // Comunidades
  listComunidades: function listComunidades() {
    return session.get('/api/v1/main/comunidad/');
  },
  allComunidades: function allComunidades() {
    return session.get('/api/v1/main/clv/');
  },
  detalleComunidad: function detalleComunidad(id) {
    return session.get('/api/v1/main/comunidad/' + id + '/');
  },
  nuevaComunidad: function nuevaComunidad(data) {
    return session.post('/api/v1/main/comunidad/', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  eliminarComunidad: function eliminarComunidad(id) {
    return session.delete('/api/v1/main/comunidad/' + id + '/');
  },
  actualizarComunidad: function actualizarComunidad(data, id) {
    return session.patch('/api/v1/main/comunidad/' + id + '/', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  listMiembrosComunidad: function listMiembrosComunidad(idComunidad) {
    return session.get("/api/v1/main/comunidad/".concat(idComunidad, "/miembros/"));
  },
  listCategoriasComunidad: function listCategoriasComunidad(idComunidad) {
    return session.get("/api/v1/main/comunidad/".concat(idComunidad, "/categorias/"));
  },
  // Propiedades
  listEstablecimientos: function listEstablecimientos() {
    return session.get('/api/v1/main/establecimiento/');
  },
  listEstablecimientosUsuario: function listEstablecimientosUsuario(idUsuario) {
    return session.get("/api/v1/main/establecimiento/?usuario=".concat(idUsuario));
  },
  nuevoEstablecimiento: function nuevoEstablecimiento(data) {
    return session.post("/api/v1/main/establecimiento/", data);
  },
  getEstablecimiento: function getEstablecimiento(idEstablecimiento) {
    return session.get("/api/v1/main/establecimiento/".concat(idEstablecimiento, "/"));
  },
  actualizarEstablecimiento: function actualizarEstablecimiento(data) {
    return session.patch("/api/v1/main/establecimiento/".concat(data.id, "/"), data);
  },
  eliminarEstablecimiento: function eliminarEstablecimiento(idEstablecimiento) {
    return session.delete("/api/v1/main/establecimiento/".concat(idEstablecimiento, "/"));
  },
  // Usuarios
  listUsuarios: function listUsuarios() {
    return session.get('/api/v1/main/miembro/');
  },
  actualizarUsuario: function actualizarUsuario(id, data) {
    return session.patch('/api/v1/main/miembro/' + id + '/', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  eliminarMiembro: function eliminarMiembro(id) {
    return session.delete('/api/v1/main/miembro/' + id + '/');
  },
  getMiembro: function getMiembro(username) {
    return session.get('/api/v1/main/miembro/?username=' + username);
  },
  getMiembroById: function getMiembroById(idMiembro) {
    return session.get('/api/v1/main/miembro/' + idMiembro + '/');
  },
  invitarUsuario: function invitarUsuario(data) {
    return session.post('/api/v1/main/iav/', data);
  },
  verificarMiembro: function verificarMiembro(data) {
    return session.post('/api/v1/main/vmv/', data);
  },
  //Grupos
  listGrupos: function listGrupos() {
    return session.get('/api/v1/main/glv/');
  },
  // Documentos Privados
  misDocumentos: function misDocumentos() {
    return session.get('/api/v1/main/carpeta-privada/');
  },
  DocumentosMiembro: function DocumentosMiembro(idCarpeta) {
    if (idCarpeta) {
      return session.get('/api/v1/main/carpeta-privada/' + idCarpeta + '/');
    } else {
      return session.get('/api/v1/main/carpeta-privada/');
    }
  },
  eliminarDocumentoPrivado: function eliminarDocumentoPrivado(idDocumento) {
    return session.delete('/api/v1/main/documento-carpeta-privada/' + idDocumento + '/');
  },
  nuevoDocumentoPrivado: function nuevoDocumentoPrivado(data) {
    return session.post('/api/v1/main/documento-carpeta-privada/', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
};