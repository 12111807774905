import "core-js/modules/es6.promise";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.regexp.replace";
import axios from 'axios';
import store from "../store";
import Notify from 'quasar/src/plugins/Notify.js';;
import _ from 'lodash';
var CSRF_COOKIE_NAME = 'csrftoken';
var CSRF_HEADER_NAME = 'X-CSRFToken';
var session = axios.create({
  xsrfCookieName: CSRF_COOKIE_NAME,
  xsrfHeaderName: CSRF_HEADER_NAME,
  baseURL: process.env.API
});

var networkConnectionError = _.debounce(function () {
  Notify.create({
    message: 'Error de conexión con el servidor.',
    color: 'negative'
  });
}, 2500);

session.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  var request = error.request;
  var response = error.response; // Si hay un respuesta de error desde el backend

  if (response) {
    if (error.response.status === 401) {
      store.dispatch('auth/logout');
      window.location.replace('/login');
    }
  } else {
    // Lo mas probable sea un problema de conexion con el backend
    if (request && request.readyState === 4 && request.status === 0) {
      networkConnectionError.cancel();
      networkConnectionError();
    }
  }

  return new Promise(function (resolve, reject) {
    reject(error);
  });
});
export default session;