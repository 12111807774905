import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/home/daniel/Proyectos/gcom/frontend/node_modules/@babel/runtime-corejs2/helpers/defineProperty";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.find-index";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import state from "./state";
export function setComunidad(state, nombre) {
  state.nombreComunidad = nombre;
}
export function setCategoria(state, nombre) {
  state.nombreCategoria = nombre;
}
/**
 * Guarda en el state el tab activo en la pagina de detalles de una comunidad.
 * @param {Object} state State de Vuex
 * @param {String} nombre Nombre del tab activo
 */

export function setTabActivo(state, nombre) {
  state.stDetalleComunidad.tabActivo = nombre;
}
/**
 * Guarda en el state el panel activo de las encuestas en la pagina de detalles de una comunidad.
 * @param {Object} state State de Vuex
 * @param {Array} params Array con el nombre y el valor (Boolean)
 */

export function setPanelEncuestasActivo(state, params) {
  var pe = state.stDetalleComunidad.panelEncuestasActivo;
  pe[params[0]] = params[1];
  state.stDetalleComunidad.panelEncuestasActivo = pe;
}
/**
 * Cambia estado de variable leftDrawerOpen en el state
 * */

export function toggleDrawerLayout(state) {
  state.leftDrawerOpen = !state.leftDrawerOpen;
}
/**
 * Establece el ID del usuario a editar
 * @param {Object} state
 * @param {Number} idUser
 */

export function setUserProperty(state, idUser) {
  state.idUserProperty = idUser;
}
/** Establece el listado de establecimientos de un usaurio
 * @param {Object} state State de Vuex
 * @param {Array} params array con listado de propiedades
 */

export function setPropertyList(state, data) {
  state.propiedades = data;
}
/**
 * Establece los datos de una propedad
 * @param {Object} state
 * @param {Object} data
 */

export function setPropertyDetail(state, data) {
  state.propiedad = data;
}
/**
 * Anade una nueva propiedad al listado de propiedades
 * @param {Object} state
 * @param {Object} property
 */

export function addProperty(state, property) {
  state.propiedades.push(property);
}
/**
 * Actualiza una propiedad del listado de propiedades
 * @param {Object} state
 * @param {Object} property
 */

export function updateProperty(state, property) {
  var index = state.propiedades.findIndex(function (item) {
    return item.id === property.id;
  });

  if (index !== -1) {
    Object.assign(state.propiedades[index], _objectSpread({}, property)); // state.propiedades[index] = property // Esto no es reactivo
  }
}
/**
 * Elimina una propiedad del listado de propiedades
 * @param {Object} state
 * @param {Number} idProperty
 */

export function deleteProperty(state, idProperty) {
  var index = state.propiedades.findIndex(function (item) {
    return item.id === idProperty;
  });

  if (index !== -1) {
    state.propiedades.splice(index, 1);
  }
}
/**
 * Muestra/Oculpara el Dialog para editar propiedades
 * @param {Object} state State de Vuex
 * @param {Boolean} data Establece si se muestra u oculta el dialog para para editar propiedad
 */

export function showDialogEditProperty(state, data) {
  state.dialogEditProperty = data;
}