import store from "../store";

var requireAuthenticated = function requireAuthenticated(to, from, next) {
  store.dispatch("auth/initialize").then(function () {
    if (!store.getters["auth/isAuthenticated"]) {
      next("/login?next=".concat(encodeURIComponent(to.fullPath)));
    } else {
      if (store.getters['auth/isVerified'] === false) {
        next('profile');
      }
    }

    next();
  }).catch(function (error) {
    console.log('Error requireAuthenticated');
    console.dir(error);
    throw error;
  });
};

var requireUnauthenticated = function requireUnauthenticated(to, from, next) {
  store.dispatch("auth/initialize").then(function () {
    if (store.getters["auth/isAuthenticated"]) {
      next("/");
    } else {
      next();
    }
  }).catch(function (error) {
    console.log('Error requireUnauthenticated');
    console.dir(error);
    throw error;
  });
};

var redirectLogout = function redirectLogout(to, from, next) {
  if (store.getters["auth/isSocialAuth"]) {
    /*FB.api('/me/permissions', 'DELETE', function(response) {
      console.log('DELETE /me/permissions')
      store.dispatch('auth/logout').then(() => next('/login'));
    })*/
    FB.logout(function (response) {
      store.dispatch("auth/logout").then(function () {
        return next("/login");
      });
    });
  } else {
    store.dispatch("auth/logout").then(function () {
      return next("/login");
    });
  }
};

var routes = [{
  path: "/",
  component: function component() {
    return import(
    /* webpackChunkName: "home" */
    "../layouts/Default.vue");
  },
  //beforeEnter: requireAuthenticated,
  children: [{
    path: "",
    name: "home",
    component: function component() {
      return import(
      /* webpackChunkName: "home" */
      "../pages/Home.vue");
    },
    beforeEnter: requireAuthenticated
  }, {
    path: "/login",
    name: "login",
    beforeEnter: requireUnauthenticated,
    component: function component() {
      return import(
      /* webpackChunkName: "login" */
      "../pages/Login.vue");
    }
  }, {
    path: "/logout",
    name: "logout",
    beforeEnter: redirectLogout
  }, {
    path: "/reset-password",
    name: "reset-password",
    component: function component() {
      return import(
      /* webpackChunkName: "resetPassword" */
      "../pages/ResetPassword.vue");
    } //beforeEnter: requireUnauthenticated,

  }, {
    path: "/password-reset/confirm/:uid/:token",
    name: "reset-password-confirm",
    component: function component() {
      return import(
      /* webpackChunkName: "passwordResetConfirm" */
      "../pages/PasswordResetConfirm.vue");
    }
  }, {
    path: "/change-password",
    name: "change-password",
    component: function component() {
      return import(
      /* webpackChunkName: "changePassword" */
      "../pages/ChangePassword.vue");
    },
    beforeEnter: requireAuthenticated
  }, {
    path: "/register",
    name: "register",
    component: function component() {
      return import(
      /* webpackChunkName: "register" */
      "../pages/Register.vue");
    }
  }, {
    path: "/register/:cod",
    name: "register-cod",
    props: true,
    component: function component() {
      return import(
      /* webpackChunkName: "register" */
      "../pages/Register.vue");
    }
  }, {
    path: "/register/account-confirm-email/:key",
    name: "verify-email",
    component: function component() {
      return import(
      /* webpackChunkName: "verifyEmail" */
      "../pages/VerifyEmail.vue");
    }
  }]
}, // Comunidades
{
  path: "/comunidades/",
  component: function component() {
    return import(
    /* webpackChunkName: "DefaultLayout" */
    "../layouts/Default.vue");
  },
  //beforeEnter: requireAuthenticated,
  meta: {
    Superadmins: true,
    Administradores: true
  },
  children: [{
    path: "",
    name: "comunidades",
    component: function component() {
      return import(
      /* webpackChunkName: "Comunidades" */
      "../pages/Home.vue");
    },
    beforeEnter: requireAuthenticated,
    meta: {
      Superadmins: true,
      Administradores: true,
      Gestores: true
    }
  }, {
    path: "nueva",
    name: "addComunidad",
    component: function component() {
      return import(
      /* webpackChunkName: "AddComunidad" */
      "../pages/AddEditComunidad.vue");
    },
    beforeEnter: requireAuthenticated,
    meta: {
      Superadmins: true,
      Administradores: true
    }
  }, {
    path: ":id",
    name: "detallesComunidad",
    component: function component() {
      return import(
      /* webpackChunkName: "DetallesComunidad" */
      "../pages/DetallesComunidad.vue");
    },
    beforeEnter: requireAuthenticated
  }, {
    path: ":id/editar",
    name: "editComunidad",
    component: function component() {
      return import(
      /* webpackChunkName: "EditComunidad" */
      "../pages/AddEditComunidad.vue");
    },
    beforeEnter: requireAuthenticated,
    meta: {
      Superadmins: true,
      Administradores: true
    }
  }, {
    path: ":id/encuesta/",
    name: "nuevaEncuesta",
    component: function component() {
      return import(
      /* webpackChunkName: "NuevaEncuesta" */
      "../pages/NuevaEncuesta.vue");
    },
    beforeEnter: requireAuthenticated,
    meta: {
      Superadmins: true,
      Administradores: true
    }
  }, {
    path: ":id/encuesta/:idEncuesta",
    name: "editarEncuesta",
    component: function component() {
      return import(
      /* webpackChunkName: "NuevaEncuesta" */
      "../pages/NuevaEncuesta.vue");
    },
    beforeEnter: requireAuthenticated,
    meta: {
      Superadmins: true,
      Administradores: true
    }
  }, {
    path: ":id/encuesta/detalle/:idEncuesta",
    name: "detalleEncuesta",
    component: function component() {
      return import(
      /* webpackChunkName: "DetalleEncuesta" */
      "../pages/DetalleEncuesta.vue");
    },
    beforeEnter: requireAuthenticated,
    meta: {
      Superadmins: true,
      Administradores: true
    }
  }, {
    path: ":idComunidad/documentos-publicos",
    name: "categoriaPublica",
    component: function component() {
      return import(
      /* webpackChunkName: "CategoriaPublica" */
      "../pages/CategoriaPublica.vue");
    },
    beforeEnter: requireAuthenticated
  }, {
    path: ":idComunidad/nuevo-doc-publico",
    name: "nuevoDocumentoPublico",
    component: function component() {
      return import(
      /* webpackChunkName: "NuevoDocPublico" */
      "../pages/NuevoDocPublico.vue");
    },
    beforeEnter: requireAuthenticated,
    meta: {
      Superadmins: true,
      Administradores: true,
      Gestores: true
    }
  }, {
    path: ":idComunidad/:idCategoria",
    name: "categoria",
    component: function component() {
      return import(
      /* webpackChunkName: "Categoria" */
      "../pages/Categoria.vue");
    },
    props: function props(route) {
      return {
        idComunidad: parseInt(route.params.idComunidad),
        idCategoria: parseInt(route.params.idCategoria)
      };
    },
    beforeEnter: requireAuthenticated
  }, {
    path: ":idComunidad/:idCategoria/editar",
    name: "editCategoria",
    component: function component() {
      return import(
      /* webpackChunkName: "EditCategoria" */
      "../pages/EditCategoria.vue");
    },
    beforeEnter: requireAuthenticated,
    meta: {
      Superadmins: true,
      Administradores: true,
      Gestores: true
    }
  }, {
    path: ":idComunidad/:idCategoria/nuevo-doc",
    name: "nuevoDocumento",
    component: function component() {
      return import(
      /* webpackChunkName: "NuevoDoc" */
      "../pages/NuevoDoc.vue");
    },
    beforeEnter: requireAuthenticated,
    meta: {
      Superadmins: true,
      Administradores: true,
      Gestores: true
    }
  }]
}, // Usuarios
{
  path: "/usuarios/",
  component: function component() {
    return import(
    /* webpackChunkName: "DefaultLayout" */
    "../layouts/Default.vue");
  },
  //beforeEnter: requireAuthenticated,
  meta: {
    Superadmins: true,
    Administradores: true
  },
  children: [{
    path: "",
    name: "usuarios",
    component: function component() {
      return import(
      /* webpackChunkName: "Usuarios" */
      "../pages/Usuarios.vue");
    },
    beforeEnter: requireAuthenticated,
    meta: {
      Superadmins: true,
      Administradores: true
    }
  }]
}, // Documentos
{
  path: "/documentos/",
  component: function component() {
    return import(
    /* webpackChunkName: "DefaultLayout" */
    "../layouts/Default.vue");
  },
  meta: {
    Superadmins: true,
    Administradores: true
  },
  children: [{
    path: "",
    name: "documentos",
    component: function component() {
      return import(
      /* webpackChunkName: "MisDocumentos" */
      "../pages/MisDocumentos.vue");
    },
    beforeEnter: requireAuthenticated
  }, {
    path: ":idCarpeta/",
    name: "documentosMiembro",
    component: function component() {
      return import(
      /* webpackChunkName: "MisDocumentos" */
      "../pages/MisDocumentos.vue");
    },
    beforeEnter: requireAuthenticated,
    meta: {
      Superadmins: true,
      Administradores: true,
      Gestores: true
    }
  }, {
    path: "nuevo/",
    name: "nuevoDocumentoMiembro",
    component: function component() {
      return import(
      /* webpackChunkName: "NuevoDocPrivado" */
      "../pages/NuevoDocPrivado.vue");
    },
    beforeEnter: requireAuthenticated,
    meta: {
      Superadmins: true,
      Administradores: true,
      Gestores: true
    }
  }, {
    path: ":idCarpeta/nuevo/",
    name: "crearNuevoDocumentoMiembro",
    component: function component() {
      return import(
      /* webpackChunkName: "NuevoDocPrivado" */
      "../pages/NuevoDocPrivado.vue");
    },
    beforeEnter: requireAuthenticated,
    meta: {
      Superadmins: true,
      Administradores: true,
      Gestores: true
    }
  }]
}, // Propiedades
{
  path: "/propiedades/",
  component: function component() {
    return import(
    /* webpackChunkName: "DefaultLayout" */
    "../layouts/Default.vue");
  },
  //beforeEnter: requireAuthenticated,
  meta: {
    Superadmins: true,
    Administradores: true
  },
  children: [{
    path: "",
    name: "propiedades",
    component: function component() {
      return import(
      /* webpackChunkName: "Propiedades" */
      "../pages/Propiedades.vue");
    },
    beforeEnter: requireAuthenticated,
    meta: {
      Superadmins: true,
      Administradores: true,
      Gestores: true,
      Miembros: true
    }
  }, {
    path: "nueva",
    name: "nuevaPropiedad",
    component: function component() {
      return import(
      /* webpackChunkName: "PropiedadesEdit" */
      "../pages/PropiedadesEdit.vue");
    },
    beforeEnter: requireAuthenticated,
    meta: {
      Superadmins: true,
      Administradores: true,
      Gestores: true,
      Miembros: true
    }
  }, {
    path: ":idPropiedad/editar",
    name: "editPropiedad",
    component: function component() {
      return import(
      /* webpackChunkName: "PropiedadesEdit" */
      "../pages/PropiedadesEdit.vue");
    },
    beforeEnter: requireAuthenticated,
    meta: {
      Superadmins: true,
      Administradores: true,
      Gestores: true,
      Miembros: true
    }
  }]
}, // Perfil de usuario
{
  path: "/profile/",
  component: function component() {
    return import(
    /* webpackChunkName: "DefaultLayout" */
    "../layouts/LayoutProfile.vue");
  },
  children: [{
    path: "",
    name: "profile",
    component: function component() {
      return import(
      /* webpackChunkName: "profile" */
      "../pages/EditProfile.vue");
    },
    // beforeEnter: requireAuthenticated
    beforeEnter: function beforeEnter(to, from, next) {
      // Si no esta autenticado lo enviamos al login
      if (!store.getters["auth/isAuthenticated"]) {
        next("login");
      }

      next(); // Si el usuario esta autenticado y verificado lo dejamos pasar
    }
  }]
}, // 404.html
{
  path: "*",
  name: "page-not-fount",
  component: function component() {
    return import(
    /* webpackChunkName: "page-not-found" */
    "../pages/404.vue");
  }
}];
export default routes;